import React from 'react';
import Total from "../../Total/Total";
import { setNonRegisteredTotalYou } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const NonRegisteredTotalYou = ( props ) => {
    const onChange = val => {
        props.setNonRegisteredTotalYou(val)
    }

    return (
        < Total
            total={props.nonRegisteredTotal}
            title={'Total in Non-Registered'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        nonRegisteredTotal: state.savingsAndInvestments.you.nonRegistered.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setNonRegisteredTotalYou : val => dispatch(setNonRegisteredTotalYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NonRegisteredTotalYou);